import TradingViewWidget from "react-tradingview-widget";

function TradingViewChart({ symbol }) {
  return (
    <div className="charts" style={{ height: "100%" }}>
      <TradingViewWidget
        symbol={symbol}
        locale="fr"
        height="100%"
        autosize={true}
        theme="dark"
        interval="D"
        timezone="Etc/UTC"
        style="1"
        enable_publishing={false}
        hide_top_toolbar={true}
        hide_legend={true}
        save_image={false}
        hide_volume={true}
      />
    </div>
  );
}
export default TradingViewChart;
